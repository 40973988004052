<template>
  <v-container>

    <Titulo></Titulo>
    <NavBar :step="1"></NavBar>

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header style="box-shadow: none">
        <v-stepper-step :complete="e1 > 1" step="1"> Inicio </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2"> Mascotas </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3"> Planes </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4"> Tus datos </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="2">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col style="max-width: 300px">
                  <h3 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C;">
                    Cuéntanos sobre tu mascota</h3>

                  <h4 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                    Selecciona una opción</h4>

                  <v-col>
                    <v-radio-group required v-model="petData.species" v-on:change="getBreeds()" @change="change()">
                      <v-radio v-for=" (option, index) in speciesOptions" :key="option.id" :value="option"
                        :class="mascotaVista" off-icon on-icon>
                        <template v-slot:label>
                          <div style="display: flex; flex-direction: column; justify-content: center;">
                            <img width="130px" :alt="option.name" :src="option.image" class="species-icon" />
                            <br>
                            <label style="margin:auto;" v-if="index == 0">Perro</label>
                            <v-radio-group v-if="index == 0" v-model="petSelect">
                              <v-radio v-model="selecPPet" style="margin: auto;"></v-radio>
                            </v-radio-group>
                            <label style="margin:auto;" v-if="index == 1">Gato</label>
                            <v-radio-group v-if="index == 1" v-model="catSelect">
                              <br>
                              <v-radio v-model="selecPPet" style="margin: auto;"></v-radio>
                            </v-radio-group>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>

                  <div v-if="petData.species">

                    <v-col>
                      <p style="display: flex; text-align: start; margin-bottom: 3%; color: #525252;">Nombre</p>
                      <v-text-field v-model="petData.petName" :rules="nameRules" label="Ingresa el nombre de tu mascota"
                        maxlength="51" @change="change()" required solo filled style="box-sizing: border-box; height: 57px; margin: auto; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); 
                            box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); border-radius: 6px;">
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <p style="display: flex; text-align: start; margin-bottom: 3%; color: #525252;">Raza</p>
                      <v-select :disabled="!petData.species" :items="breeds" v-model="petData.breed" :rules="breedRules"
                        return-object @change="change()" item-text="name" label="Selecciona la raza" required solo
                        filled
                        style="box-sizing: border-box; height: 57px; margin: auto; background: #FFFFFF;
                          border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); border-radius: 6px;">
                      </v-select>
                    </v-col>

                    <v-col>
                      <p style="display: flex; text-align: start; margin-bottom: 3%; color: #525252;">Edad</p>
                      <v-select :items="ages" v-model="petData.age" :rules="ageRules" :loading="loadingAges"
                        return-object @change="change()" :disabled="loadingAges" item-text="label"
                        label="Selecciona la edad de tu mascota" required solo filled
                        style="box-sizing: border-box; height: 57px; margin: auto; background: #FFFFFF; 
                          border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); border-radius: 6px;">
                      </v-select>
                    </v-col>

                    <v-col>
                      <p style="display: flex; text-align: start; margin-bottom: 3%; color: #525252;">Género</p>
                      <v-select :items="sexOptions" v-model="petData.sex" :rules="sexRules" item-text="label"
                        @change="change()" return-object label="Selecciona el géreno de tu mascota" required solo filled
                        style="box-sizing: border-box; height: 57px; margin: auto; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); 
                        box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); border-radius: 6px;">
                      </v-select>
                    </v-col>

                    <v-col>
                      <br>

                      <div class="button-container">
                        <v-btn
                          style="background-color: #2D387C; color: white; border-radius: 12px; width: 100%; height: 120%; margin: auto;"
                          @click="validate()">
                          Continuar
                        </v-btn>
                      </div>

                    </v-col>

                  </div>

                  <div class="button-container">

                    <v-btn style="margin-left: 30%;" text :to="{ name: 'Step1' }"> Atras </v-btn>

                  </div>

                </v-col>
              </div>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>
import catIcon from "@/assets/images/catt2.png";
import dogIcon from "@/assets/images/dogg.png";

import catIcon2 from "@/assets/images/catt.png";
import dogIcon2 from "@/assets/images/dogg2.png";

import BreedsService from "../services/breeds.service";
import AgeService from "../services/age.service";
import ShieldService from "../services/shield.service";
import NavBar from "@/components/NavBar.vue";
import Titulo from "../components/Titulo.vue";

import CryptoJS from 'crypto-js'

export default {
  name: "DetailForm",
  components: { NavBar, Titulo },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    petSelect: true,
    catSelect: true,
    valid: false,
    e1: 2,
    stepId: null,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      address: null,
      addressNumber: null,
      addressDepto: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      dates: null,
      day: null,
      month: null,
      year: null,
      stepId: null,
      coverType: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'update',
    },
    petData: {
      species: null,
      breed: null,
      breedCode: null,
      petName: null,
      age: null,
      sex: null,
      hasMicrochip: false,
    },
    screen: window.screen.width,
    sexOptions: [
      {
        id: "male",
        label: "Macho",
        petSex: "M"
      },
      {
        id: "female",
        label: "Hembra",
        petSex: "H"
      },
    ],
    flag: false,
    selecPPet: false,
    breedRules: [(v) => !!v || "Necesitamos saber la raza de tu mascota"],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    ageRules: [(v) => !!v || "Debes indicar la edad de tu mascota"],
    sexRules: [(v) => !!v || "Selecciona el sexo de tu mascota"],
    microchipRules: [
      (v) => !!v || "Tu mascota debe tener microchip para contratar el seguro",
    ],
    selectedSpecies: null,
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    breeds: [],
    speciesOptions: [
      {
        id: "dog",
        name: "Perro",
        image: dogIcon2,
      },
      {
        id: "cat",
        name: "Gato",
        image: catIcon2,
      },
    ],
    ages: [],
    loadingAges: false,
  }),
  async mounted() {

    // this.userData.stepId = sessionStorage.getItem('stepId');

    const query = this.$route.query;
    const petDataAux = sessionStorage.getItem('petData');
    const userDataAux = sessionStorage.getItem('userData');
    const servicesDataAux = sessionStorage.getItem('servicesData');

    if (process.env.VUE_APP_TIPO == 'Senior') {
      this.getAgesSenior()
    } else {
      this.getAges()
    }

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.petData = abandoned.petData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const petDataLocal = JSON.stringify(this.petData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();

      sessionStorage.setItem('userData', encryptedUserDataLocal);
      sessionStorage.setItem('servicesData', encryptedServicesDataLocal);
      sessionStorage.setItem('petData', encryptedPetDataLocal);

    } else if (petDataAux) {
      const bytesPetData = CryptoJS.AES.decrypt(petDataAux, this.secretKey);
      const decryptedDataPet = bytesPetData.toString(CryptoJS.enc.Utf8);
      this.petData = JSON.parse(decryptedDataPet);
      console.log("petData: ", this.petData);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      console.log("servicesData: ", this.servicesData);

    } else {
      const petDataLocal = JSON.stringify(this.petData);
      const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
      sessionStorage.setItem('petData', encryptedPetDataLocal);

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      console.log("servicesData: ", this.servicesData);
    }

    this.getBreeds();

    if (this.screen > 800) {
      this.mascotaVista = 'mascota'
    } else {
      this.mascotaVista = 'mascota2'
    }

  },
  methods: {
    back() {
      this.$emit("back");
    },
    message() {
      this.$alert("El chip para tu mascota y posterior registro en www.registratumascota.cl, es un requisito para la ley N°21020 de Tenencia Responsable o Ley Cholito. No olvides realizar este proceso.", "Información", "info")
    },
    async validate() {
      if (this.$refs.form.validate()) {

        const petDataLocal = JSON.stringify(this.petData);
        const encryptedPetDataLocal = CryptoJS.AES.encrypt(petDataLocal, this.secretKey).toString();
        sessionStorage.setItem('petData', encryptedPetDataLocal);

        if (this.userData.email && this.activeCampaingUse == 'TRUE') {
          const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

          this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;
          await ShieldService.updateContactActiveCampain(this.userData, null, this.petData, this.servicesData, 4);

          const servicesDataLocal = JSON.stringify(this.servicesData);
          const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
          sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

        }

        this.$router.push({ path: '/mt/planes' })
      }
    },
    async getBreeds() {
      if (this.petData.species) {
        // this.petData.breed = null;
        const data = await BreedsService.getBreeds(this.petData.species.id);

        // Transforma la estructura a un array plano para el v-select
        this.breeds = data.flatMap(category => category);

        if (this.petData.species.id == 'dog') {
          this.speciesOptions[0].image = dogIcon
          this.speciesOptions[1].image = catIcon2
          this.petSelect = false
          this.catSelect = true
        } else if (this.petData.species.id == 'cat') {
          this.speciesOptions[0].image = dogIcon2
          this.speciesOptions[1].image = catIcon
          this.petSelect = true
          this.catSelect = false
        }
      }
    },
    async getAges() {
      this.loadingAges = true;
      this.ages = await AgeService.getAges();
      this.loadingAges = false;
    },

    async getAgesSenior() {
      this.loadingAges = true;
      this.ages = await AgeService.getAgesSenior();
      this.loadingAges = false;
    },
    async change() {

      try {
        await ShieldService.newCreateStep(null, this.petData, null, this.servicesData, 'Mascotas');
      } catch (error) {
        console.log("Cart Fail");
      }
    },
  }
};

</script>
<style lang="scss">
.mascota {
  width: 100%;
  margin-left: -13%;
  color: white;
}

.mascota2 {
  width: 100%;
  margin-left: -12%;
  color: white;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.species-container {
  display: block;
  position: relative;
  text-align: center;
  // margin-bottom: 2rem;
  cursor: pointer;

  .species-selector {
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    // padding: 0.5rem 1rem;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    // border: 1px solid #f5f5f5;
    cursor: pointer;

    .radio-input {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    &.active {
      background-color: #f5f5f5;
      box-shadow: 4px 4px 4px #c6c6c6;
    }

    input[type="radio"] {
      position: absolute;
      left: 0;
      opacity: 0;
      outline: none;
      z-index: -1;
    }

    .species-icon {
      height: 100px;
      cursor: pointer;
    }
  }
}

.v-col {
  text-align: left;
}

.v-input--radio-group--column .v-input--radio-group__input {
  flex-direction: row !important;
}

@media (max-width: 800px) {
  .form-container {
    flex-direction: column;
    align-items: center;
  }
}
</style>