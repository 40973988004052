<template>
  <header>
    <div style="max-width: 95%;  margin-top: 2%">

      <div :class="[isMobile ? 'mobile-header' : 'desktop-header']">

        <div v-if="screen < 800" class="mobile-logo-container" style="padding-top: 5%;">

          <router-link v-if="step != 0" :to="path">

            <v-icon
              style=" width: 10px; height: 10px; position: absolute; left:9%; color: white; top: 42px;">
              mdi-chevron-left
            </v-icon>

          </router-link>

          <router-link v-else :to="path">

            <v-icon @click="sitio()"
              style=" width: 10px; height: 14px; position: absolute; left:10%; color: white; top: 42px;">
              mdi-chevron-left
            </v-icon>

          </router-link>
          <!-- 
          <router-link :to="{ path: '/hg/' }"> -->
          <!-- <img :src="logo" alt="logo" width="150px" height="35px" class="mobile-logo"> -->
          <!-- </router-link> -->

        </div>

        <div v-else class="desktop-logo-container" >

          <!-- <router-link :to="{ path: '/hg/' }"> -->

          <!-- <img :src="logoNav" alt="Image 1" class="menu-nav"
            style="padding-top: 15px; float: right; padding-right: 36px; max-height: 52px; max-width: 221px; margin-top: -3px;"> -->

          <!-- </router-link> -->

          <div>

            <router-link :to="path">

              <div v-if="step == 0">

                <v-icon @click="sitio()" class="iconStyle2">
                  mdi-chevron-left
                </v-icon>

                <span @click="sitio()" class="textoH3" style="margin-top: -45px;
                  margin-right: 100px;">Volver atrás</span>


              </div>

              <div v-else>

                <v-icon class="iconStyle">
                  mdi-chevron-left
                </v-icon>

                <span class="textoH3" style="margin-left: 2px; margin-top: -45px;">Volver atrás</span>

              </div>

            </router-link>

          </div>

        </div>

      </div>

    </div>

  </header>
</template>
<script>
import logo from '@/assets/images/LogoFooterNew.png';
import MenuNav from '@/assets/MenuNav.png';
import logoNav from '@/assets/images/LogoFooterNew.png';
export default {
  name: 'MyHeader',
  props: ['step', "userData", "beneficiaries"], // Prop para pasar datos adicionales del botón de retroceso
  data() {
    return {
      TipoPlan: '',
      displayOptions: false,
      smallScreen: false,
      logo,
      logoNav,
      MenuNav,
      isMobile: false,
      path: '',
      screen: window.screen.width,
    };
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 789;
    },
    sitio() {
      window.location.href = "https://www.sbseguros.cl/"
    }
  },
  mounted() {

    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    if (this.step == 0) {
      this.path = {
        path: ""
      }
    } else if (this.step == 1) {
      this.path = {
        path: "/mt/"
      }
    } else if (this.step == 2) {
      this.path = {
        path: "/mt/mascotas"
      }
    } else if (this.step == 3) {
      this.path = {
        path: `/mt/planes`
      }
    } else if (this.step == 4) {
      this.path = {
        path: "/mt/datos"
      } 
    }

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style lang="scss">
/* Estilos para el desktop */
.desktop-header {
  height: 65px;
  // background: #FFFFFF;
  // border: 2px solid rgba(0, 44, 119, 1);
  // box-shadow: 0px 24px 30px rgba(0, 0, 0, 0.03);
  // border-radius: 15px;

  .desktop-logo {
    width: 270px;
    height: 60px;
  }

  .desktop-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }
}

/* Estilos para mobile */
.mobile-header {
  .mobile-logo {
    display: flex;
    flex-direction: column;
    right: 0;
    justify-content: space-around;
  }
}

.mobile-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconStyle {
  width: 20px; 
  height: 20px; 
  color: rgba(51, 51, 204, 1); 
  font-size: 20px;  
  margin-top: -100px;
  margin-left: 4%;
}

.iconStyle2 {
    width: 20px; 
    height: 20px; 
    color: rgba(51, 51, 204, 1); 
    font-size: 20px;  
    margin-top: -100px;
}

@media (min-width: 1920px){
  .iconStyle {
    width: 20px; 
    height: 20px; 
    color: rgba(51, 51, 204, 1); 
    font-size: 20px;  
    margin-top: -100px;
    margin-left: 5%;
}
.iconStyle2 {
    width: 20px; 
    height: 20px; 
    color: rgba(51, 51, 204, 1); 
    font-size: 20px;  
    margin-top: -100px;
    margin-left: 2%;
}
}

.textoH3 {
  position: absolute;
  width: 127px;
  height: 22px;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  /* primary */
  color: rgba(0, 44, 119, 1);
}
</style>







