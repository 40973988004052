<template>
  <v-container>

    <Titulo></Titulo>
    <NavBar :step="0" />

    <v-row  v-if="screen > 800" class="margin-top">
      <img :src="casita" alt="Image c" class="casita-img">
      <img :src="banner" alt="Image b" class="banner-img">
    </v-row>

    <v-col v-if="screen < 800" style="margin-top: -9%; margin-bottom: -14%;">
      <img :src="bannerMobile" alt="Image b" class="banner-img" style="margin: auto; width: 100%; margin-top: -10%;">
          <img :src="casitaMobile" alt="Image c" class="casita-img" style="margin-top: -5%;">
      <v-col>
      </v-col>
    </v-col>
    
    <br>

    <v-stepper :class="vSteperStyle" v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Mascotas
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Planes
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> Tus datos
          </v-stepper-step>
        </div>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <div class="step-data">

            <v-form ref="form" v-model="valid" max-witdh="300px">

              <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 22px; line-height: 26px;  letter-spacing: -0.01em; color: #2D387C; mix-blend-mode: darken;">
                ¡Hola!</h3>
              <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C;">
                ¿Cómo te llamas?</h3>
              <br>

              <v-row>
                <v-col>
                  <p style="display: flex; text-align: start; margin-bottom: 3%; color: #525252;">Nombre</p>
                  <v-text-field v-model="userData.name" :rules="nameRules" label="Nombre" required solo filled
                    maxlength="51" @change="change()" style="box-sizing: border-box; height: 57px; margin:auto;
                        background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                        border-radius: 6px;"></v-text-field>
                  <br>
                  <p style="display: flex; text-align: start; margin-bottom: 3%; color: #525252;">Apellido</p>
                  <v-text-field v-model="userData.surname" @change="change()" :rules="surnameRules" label="Apellido"
                    maxlength="51" required solo filled style="box-sizing: border-box; height: 57px;
                        background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); margin:auto;
                        border-radius: 6px;"></v-text-field>
                </v-col>

              </v-row>

              <br>
              <br>

              <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C;">
                ¿Quieres un descuento en tu tarifa?</h3>

              <h4 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                Ingresa tu email y obtén un descuento en el precio de tu seguro</h4>

              <v-col>
                <p style="display: flex; text-align: start; margin-bottom: 3%; color: #525252;">Email</p>
              <v-text-field v-model="userData.email" @change="change()" maxlength="51" :rules="emailRules"
                label="Ingresa tu correo electrónico" solo filled style="box-sizing: border-box; height: 57px;  margin:auto;
                        background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                        border-radius: 6px;"></v-text-field>
              </v-col>
              

              <!-- <br>
              <br> -->

              <!-- <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C;">
                ¿Cuál es tu teléfono?</h3>

              <h4 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                Así también podremos enviarte la información a tu celular.</h4>

              <v-row>

                <v-col class="text-center" style="display:flex; justify-content: center;">
                  <v-text-field v-model="prefijoTelefono" label="+569" disabled solo filled style="box-sizing: border-box; max-width: 65px; height: 57px;
                        background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                        border-radius: 6px 0 0 6px; "></v-text-field>

                  <v-text-field v-model="userData.phoneNumber" @change="change()" :rules="phoneRules" :counter="8"
                    maxlength="8" label="0 000 0000" required solo filled style="box-sizing: border-box; height: 57px; 
                        background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); 
                        border-radius:0 6px 6px 0; "></v-text-field>
                </v-col>

              </v-row> -->

              <!-- <br>
              <br>

              <h3 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #2D387C;">
                ¿Cuál es tu fecha de nacimiento?</h3>

              <h4 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                Es para recomendarte el mejor seguro y además
                saludarte el día de tu cumpleaños.</h4> -->

              <div class="step-data">

                <!-- <v-row v-if="screen < 800">

                  <v-col>
                    <v-select :rules="monthRules" style="max-width: 85px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="months" v-model="month" :loading="loadingMonths"
                      v-on:change="getDays()" item-text="name" label="Mes" return-object required solo
                      filled></v-select>
                  </v-col>

                  <v-col>
                    <v-select :rules="dayRules" style="max-width: 85px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="days" v-model="day" :loading="loadingDays"
                      :disabled="loadingDays || month === null" item-text="name" label="Día" return-object required solo
                      filled>
                    </v-select>
                  </v-col>

                  <v-col>
                    <v-select :rules="yearRules" style="max-width: 85px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" value="2022" :items="years" v-model="year" item-text="name" label="Año"
                      return-object required solo filled></v-select>
                  </v-col>

                </v-row>

                <v-row v-else>

                  <v-col>
                    <v-select :rules="monthRules" style="max-width: 160px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="months" v-model="month" :loading="loadingMonths" v-on:change="getDays()"
                      item-text="name" label="Mes" return-object required solo filled></v-select>
                  </v-col>

                  <v-col>
                    <v-select :rules="dayRules" style="max-width: 160px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" :items="days" v-model="day" :loading="loadingDays" :disabled="loadingDays || month === null"
                      item-text="name" label="Día" return-object required solo filled>
                    </v-select>
                  </v-col>

                  <v-col>
                    <v-select :rules="yearRules" style="max-width: 160px; height: 57px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                      border-radius: 6px;" value="2022" :items="years" v-model="year" item-text="name" label="Año" return-object required
                      solo filled></v-select>
                  </v-col>

                </v-row> -->

                <v-row justify="center">
                  <v-dialog v-model="dialog" width="600px">
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Términos y Condiciones</span>
                      </v-card-title>
                      <v-card-text style="text-align: justify;">
                        Por este acto, y según lo dispuesto en la Ley N° 19.628 sobre Protección de la Vida Privada y
                        sus
                        modificaciones, autorizo expresamente a Southbridge
                        Compañía de Seguros Generales S.A. para que haga tratamiento, almacene, transmita y comunique
                        mis
                        datos
                        personales y la información que le he
                        proporcionado voluntariamente, a su matriz, filiales, sociedades coligadas, empresas
                        relacionadas,
                        sociedades del Grupo Empresarial al que pertenece y
                        terceros prestadores de servicios, estén ubicados dentro o fuera de Chile, con el propósito de
                        que
                        tanto
                        Southbridge Compañía de Seguros Generales
                        S.A. como dichas entidades i) me contacten y pongan a mi disposición, en la forma que estimen
                        pertinente, la más completa información y oferta de sus
                        productos y servicios; ii) procedan al almacenamiento y tratamiento de mis datos personales y de
                        mi
                        información personal para los objetivos del
                        cumplimiento de este Contrato de Seguro; y iii) hagan efectivos el o los convenios que pudieren
                        estar
                        asociados a los seguros que he contratado. Además,
                        por este acto, autorizo expresamente a que cualquier información, comunicación, declaración o
                        notificación que debe efectuar Southbridge Compañía de
                        Seguros Generales S.A. en virtud del presente contrato de seguros sea realizada a través de
                        correo
                        electrónico o de cualquier sistema de transmisión o
                        registro digital de la palabra escrita o verbal o por cualquier otro medio de comunicación
                        fehaciente.
                        El asegurado declara que conoce, acepta y concuerda
                        que cualquier transacción que se realice en el sitio www.sbseguros.cl con su Rut y clave
                        personal, se
                        entenderá y se reputará, para todos los efectos
                        legales, como realizada por él mismo. En tal sentido, el asegurado se hace entera y totalmente
                        responsable del uso y seguridad de su clave, no cabiéndole
                        Southbridge Compañía de Seguros Generales S.A.. ninguna responsabilidad por el uso indebido de
                        la
                        misma
                        por un tercero distinto del asegurado. El
                        asegurable se hace responsable por la veracidad de la información entregada para la contratación
                        del
                        seguro y del uso de las claves o códigos, liberando a
                        Southbridge Compañía de Seguros Generales S.A. de responsabilidad por cualquier mal uso que
                        hagan
                        terceros de la información que Southbridge
                        Compañía de Seguros Generales S.A. entregue exclusivamente a la persona del asegurable.
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                                  border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                                  letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
                          elevation="0" text @click="dialog = false">
                          Aceptar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>


                <v-hover v-if="screen > 800">
                  <v-checkbox :rules="conditionsRules" required>
                    <template v-slot:label>
                      <p style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323;
                              opacity: 0.8; text-align: justify; max-width: 400px; margin-top: 10%;" @click="change()">
                        Al ingresar tus
                        datos
                        y
                        hacer
                        clic en continuar aceptas que tu información sea utilizada para calcular tu estimación de prima,
                        recomendar un seguro y
                        poder comunicarnos contigo de acuerdo a nuestros nuestros <b><a style="color:#2D387C"
                            @click="dialog = true">Términos y Condiciones</a></b></p>
                    </template>
                  </v-checkbox>
                </v-hover>

                <v-hover v-else>
                  <v-checkbox :rules="conditionsRules" required>
                    <template v-slot:label>
                      <p style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 143.34%; letter-spacing: -0.3px; color: #232323;
                              opacity: 0.8; text-align: justify; margin-top: 20%;" @click="change()">Al ingresar tus
                        datos y hacer clic
                        en
                        continuar
                        aceptas que tu información sea utilizada para calcular tu estimación de prima, recomendar un
                        seguro y
                        poder comunicarnos contigo de acuerdo a nuestros nuestros <b><a style="color:#2D387C"
                            @click="dialog = true">Términos y Condiciones</a></b></p>
                    </template>
                  </v-checkbox>
                </v-hover>

              </div>

              <div class="button-container">
                <v-btn style="background-color: #2D387C; color: white; border-radius: 12px; width: 100%; height: 120%;"
                  @click="validate()">
                  Continuar
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
// import dateService from "@/services/date.service.js";
// import yearService from "@/services/year.service.js";
import ShieldService from "../services/shield.service";
import NavBar from "@/components/NavBar.vue";
import Titulo from "../components/Titulo.vue";
import CryptoJS from 'crypto-js'
// import swal from 'sweetalert';

import banner from "@/assets/images/Banner.svg";
import bannerMobile from "@/assets/images/BannerMobile.svg";
import casita from "@/assets/images/casita.svg";
import casitaMobile from "@/assets/images/casitaMobile.svg";

export default {
  name: "Step1",
  components: { NavBar, Titulo },
  data: () => ({
    banner,
    bannerMobile,
    casita,
    casitaMobile,
    vSteperStyle: '',
    version: null,
    secretKey: process.env.VUE_APP_CRYPT,
    e1: 1,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      regionCode: null,
      comuneCode: null,
      address: null,
      addressNumber: null,
      addressDepto: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      dates: null,
      day: null,
      month: null,
      year: null,
      stepId: null,
      coverType: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    dialog: false,
    screen: window.screen.width,
    // months: [],
    // days: ["Selecciona el mes"],
    // yars: [],
    // prefijoTelefono: null,
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    valid: false,
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => (v !== null && !(/^\s+$/.test(v)) && v.trim() !== '') || "No se permite un valor vacío",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => /^[a-zA-ZáéíóúüÜñÑ\s]+$/.test(v) || "Ingrese solo letras",
      (v) => (v !== null && !(/^\s+$/.test(v)) && v.trim() !== '') || "No se permite un valor vacío",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 carácteres"
    ],
    emailRules: [
      // Comentar la regla que hace obligatorio el correo
      // (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    conditionsRules: [
      (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
    ],
   monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    dayRules: [
      (v) => !!v || "El día es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ],
    birthRules: [
      (v) => !!v || "Debe Ingresar la edad de la persona de Mayor Edad",
      (v) => !isNaN(parseFloat(v)) && v >= 18 && v <= 79 || 'El rango de la persona de Mayor Edad debe ser entre 18 y 79 Años'
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
  }),
  async mounted() {
    // this.getMonths();
    // this.getYears();
    // this.userData.stepId = sessionStorage.getItem('stepId')

    const query = this.$route.query;
    const userDataAux = sessionStorage.getItem('userData');
    const servicesDataAux = sessionStorage.getItem('servicesData');

    if (query.stepID) {
      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();

      sessionStorage.setItem('userData', encryptedUserDataLocal);
      sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

    } else if (userDataAux) {
      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      console.log("userData: ", this.userData);

      if (servicesDataAux) {
        const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
        const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
        this.servicesData = JSON.parse(decryptedServicesData);
        console.log("serivcesData: ", this.servicesData);
      }

    } else {
      const userDataLocal = JSON.stringify(this.userData);
      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      sessionStorage.setItem('userData', encryptedUserDataLocal);
    }

    if (this.screen > 800) {
      this.vSteperStyle = 'vSteperStyle';
    } else {
      this.vSteperStyle = 'vSteperStyle2';
    }
  },
  methods: {

    async validate() {
      if (this.$refs.form.validate()) {

        // const fechaActual = new Date();
        // var newDate = this.year.id + "-" + this.month.number + "-" + this.day.id
        // var diff = fechaActual.getFullYear() - this.year.id
        // if (diff < 18) {
        //   swal({
        //     title: "Atención!",
        //     text: "Para contratar este seguro, usted debe ser mayor de edad!",
        //     icon: "info",
        //     button: "De acuerdo",
        //   });
        // } else {
        // this.userData.dates = newDate

        const userDataLocal = JSON.stringify(this.userData);
        const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
        sessionStorage.setItem('userData', encryptedUserDataLocal);

        var shieldRes;
        if (this.userData.email && this.activeCampaingUse == 'TRUE') {
          const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

          if (activeCampaingRes.data.data.meta.total == 1) {
            this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;
            await ShieldService.updateContactActiveCampain(this.userData, null, null, this.servicesData, 0);
          } else {
            shieldRes = await ShieldService.createContactActiveCampain(this.userData, this.servicesData);

            if (shieldRes.data.status == 'ok') {
              this.servicesData.contactID = shieldRes.data.data.contacts[0].id;
            }
          }

          const servicesDataLocal = JSON.stringify(this.servicesData);
          const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
          sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

        }
        this.$router.push({ path: '/mt/mascotas' })
        // }
      }
    },
    // async getMonths() {
    //   this.loadingMonths = true;
    //   this.months = await dateService.getMonths();
    //   this.loadingMonths = false;
    // },
    // async getDays() {
    //   if (this.month) {
    //     this.loadingDays = true;
    //     this.day = null;
    //     this.days = await dateService.getDays(
    //       this.month.name
    //     );
    //     this.loadingDays = false;

    //   }
    // },
    // async getYears() {
    //   this.loadingYears = true;
    //   this.years = await yearService.getYears();
    //   this.loadingYears = false;
    // },
    prefijo() {
      this.prefijoTelefono = '+56'
    },
    async change() {

      try {

        const servicesDataAux = sessionStorage.getItem('servicesData');

        if (servicesDataAux) {
          const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
          const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
          this.servicesData = JSON.parse(decryptedServicesData);
          console.log("serivcesData: ", this.servicesData);
        }

        var carro = await ShieldService.newCreateStep(this.userData, null, null, this.servicesData, 'Inicio');
        this.servicesData.type = 'update';
        this.servicesData.stepId = carro.data.data._id;

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.step-date {
  max-width: 200px;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 150px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.step-data div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--checkbox.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
  text-align: center;
  padding-bottom: 2%;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.step-data div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--checkbox.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper {
  text-align: center;
  padding-bottom: 2%;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(1)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: 'Verdana';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(3)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: 'Verdana';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(5)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: 'Verdana';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(7)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: 'Verdana';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
}

.titulo {
  background-image: url("~@/assets/images/titulo2.png");
  max-width: 350px;
  height: 150px;
  margin: auto;
}

.v-input .v-label {
  font-size: 12px;
  color: #8E8E8E;
}

.step-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: 500;
    text-align: center;
    color: #464e71;
    margin-bottom: 2rem;
  }

  h2 {
    font-weight: 500;
    color: #464e71;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
  }

  .back-container {
    width: 100%;
    align-items: flex-start;

    .back-button {


      &:hover {
        opacity: 1;
      }
    }
  }

  .button-container {
    text-align: center;
  }

  .forecast-selector {
    text-transform: uppercase;
    font-weight: 600;
    color: #464e71;
  }
}


.v-btn__content {
  padding: 0.75rem;
}

// _________________________________________________
// _________________________________________________

.first-col{
  // margin-left: 14%; 
  margin-top: -6%;
}

.second-row{
  text-align: center; 
  justify-content: start; 
  align-items: center; 
  
}

.banner-img{
  width: 100%;
  height: 220px;
  // margin-left: 40%;
  margin-bottom: 3%;
  // margin-right: 14%;
  margin-left: 44%;
  margin-top: -15%;
}

@media (min-width: 1920px){
  .banner-img{
  width: 100%;
  margin-left: 30%;
  margin-top: -10%;
}
}


// @media only screen and (min-width: 390px) and (max-width: 430px){
//   .banner-img{
//   width: 100%;
//   // margin-left: 40%;
//   }
// }



.casita-img{
  margin-right: 10px;
}

@media (max-width: 950px){
  .casita-img{
    margin-right: 10px;
    margin-left: 6%;
}
}

@media (min-width: 1920px){
  .casita-img{
    margin-left: 18%;
}
}

@media (min-width: 2560px){
  .casita-img{
    margin-left: 18%;
}
}

.seguroHogar-text{
  font-size: 20px;
  color: rgba(0, 44, 119, 1);
}

.text-banner{
  font-size: 13px;
  color: #555555;
}

@media (min-width: 951px) and (max-width: 1200px){
  .text-banner{
    font-size: 10px;
    color: #555555;
  }
}

@media (max-width: 950px){
  .text-banner{
    font-size: 12px;
    color: 555555;

  }
}

// _________________________________________________
// _________________________________________________

.margin-top{
  margin: auto; 
  margin-top: -5%;
}

@media (min-width: 1920px){
  .margin-top{
    margin: auto; 
    margin-top: -3%;
  }
}

@media (min-width: 2560px){
  .margin-top{
    margin: auto; 
    margin-top: -3%;
  }
}

// _________________________________________________
//STEPPERS
// _________________________________________________

// .vSteperStyle {
//   max-width: 50%;
//   margin: auto;
// }

// .vSteperStyle2 {
//   margin: auto;
// }
// @media only screen and (max-width: 959.98px) {
//   html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.row div.text-center.col div.v-input.prefijo-telefono.v-input--is-disabled.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed div.v-input__control div.v-input__slot div.v-text-field__slot input#input-31 {
//   width: 65px !important;
// }

// html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle2 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.row div.text-center.col div.v-input.prefijo-telefono.v-input--is-disabled.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed div.v-input__control div.v-input__slot div.v-text-field__slot input#input-31 {
//   width: 65px !important;
// }

// html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.container div.layout.justify-center div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--column.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
//   text-align: center !important;
// }

// html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.container div.layout.justify-center div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
//   text-align: center !important;
// }

// html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.container div.layout.justify-center div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--radio-group.v-input--radio-group--row.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
//   text-align: center !important;
// }

// html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0.vSteperStyle div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div.step-data form.v-form div.step-data div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--checkbox.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message {
//   text-align: center !important;
// }
// // Evita el mecanismo de esconder las letras del steper
// .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
//   display: block !important;
// }
// }

// .circle-step {
// font-size: 14px;
// width: 10%;
// }

// .v-stepper__step__step {
// // Este es el step y sus medidas
// height: 40px !important;
// width: 40px !important;
// font-size: 20px !important;
// margin-top: 30%;
// background-size: cover;
// display: none !important;
// }

// @media only screen and (max-width: 767px) {
// .v-stepper__step__step {
//   width: 42px !important;
//   height: 42px !important;
//   font-size: 20px !important;
//   margin-top: 40%;
// }
// }

.v-stepper__header {
// Stepper header - contenedor de steper completo
box-shadow: none !important;
align-items: initial !important;
margin: auto !important;
flex-wrap: nowrap !important;
width: 100% !important;
height: 45px;
}

// .v-stepper__label {
// padding: 10%;
// margin-top: 10px;
// }

// @media only screen and (max-width: 767px) {
// .v-stepper__header {
//   // Stepper header - contenedor de steper completo
//   box-shadow: none !important;
//   max-width: 100%;
//   align-items: center;
//   margin: auto;
//   width: 98% !important;
// }
// }

// .v-divider {
// display: flex;
// align-items: center;
// justify-content: center;
// width: auto;
// height: auto;
// border: none !important;
// /* Elimina la línea */
// margin: 0 10px;
// /* Espacio entre pasos */
// }

// .v-divider::before {
// content: '\003E' !important;
// /* Código de la flecha '>' */
// font-size: 24px !important;
// /* Tamaño del icono */
// color: #5C6670 !important;
// /* Color de la flecha */
// font-weight: 300 !important;
// }

// @media only screen and (max-width: 767px) {
// .v-divider::before {
//   content: '\003E' !important;
//   /* Código de la flecha '>' */
//   font-size: 24px !important;
//   /* Tamaño del icono */
//   color: #5C6670 !important;
//   /* Color de la flecha */
//   font-weight: 300 !important;
//   // height: 20px !important;
// }
// }

// .theme--light.v-stepper .v-stepper__step--active .v-stepper__label {
// text-shadow: 0px 0px 0px #4F74E3 !important;
// }

// .theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
// color: #2183f3 !important;
// }

// .v-stepper__step {
// align-items: center;
// display: flex;
// flex-direction: row;
// padding: 0px !important;
// position: relative;
// }

// @media only screen and (max-width: 767px) {
// .v-stepper__step {
//   align-items: center;
//   display: flex;
//   flex-direction: row;
//   padding: 20px; // el ancho
//   position: relative;
// }
// }

// @media only screen and (max-width: 767px) {
// .step-data {
//   display: inline !important;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }
// }

// .v-stepperstep {
// align-items: center;
// display: flex;
// flex-direction: row;
// padding: 30px; // el ancho
// position: relative;
// }

// .v-stepperheader .v-divider {
// margin: 0 -30px;
// }


// @media only screen and (max-width: 767px) {
// .circle-step {
//   font-size: 12px;
//   width: 20%;
// }
// }

// @media only screen and (max-width: 767px) {
// .v-stepper__header .v-divider {
//   margin: 0 -15px;
// }
// }

</style>