<template>
  <div>

    <br>

    <v-row justify="center">
      <v-dialog v-model="dialog" width="430px">
        <v-card>
          <v-card-text>
            <div class="text-center">
              <br>
              <br>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y
                protecciones del</label>
              <br>
              <br>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Plan <label style="font-family: Helvetica; font-style: normal; font-weight: 600;
                  font-size: 20px; line-height: 140%; letter-spacing: 0.02em;
                  color: #2D387C; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">{{ info[0][indexInfo]
                  }}</label> de
                Mascotas</label>
              <br>
              <br>
              <label class="text-center encabezado">Libre elección y sin deducible</label>
              <br>
              <br>
              <label class="texto">Puedes llevar a tu mascota a la
                clínica veterinaria <label class="textoAzul">que tú quieras</label>, sin restricciones. Además, todas
                nuestras coberturas son <label class="textoAzul">sin
                  deducible</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Urgencias Médicas</label>
              <br>
              <br>
              <label class="texto">Te devolveremos hasta el <label class="textoAzul">{{ info[1][indexInfo] }}</label> de
                tus
                gastos frente a urgencias veterinarias sin deducibles en cualquier clínica. <label
                  class="textoAzul">Tope
                  UF {{ info[2][indexInfo] }}</label>.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y hasta <label class="textoAzul">{{ info[3][indexInfo] }} veces al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Consultas Médicas</label>
              <br>
              <br>
              <label class="texto">Reembolsamos las consultas médicas y
                controles de tu mascota con el Veterinario que tú quieras. <label class="textoAzul">Tope UF
                  {{ info[4][indexInfo] }}</label>.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y <label v-if="info[5][indexInfo] == 1" class="textoAzul">{{ info[5][indexInfo] }} vez al
                  año</label> <label v-if="info[5][indexInfo] != 1" class="textoAzul">{{ info[5][indexInfo] }} veces al
                  año</label>.</label>
              <br>
              <br>

              <div v-if="indexInfo != 3">

                <label class="text-center encabezado">Medicamentos</label>
                <br>
                <br>
                <label class="texto">¿Le recetaron remedios a tu mascota?
                  Te damos hasta <label class="textoAzul">$10.000 al mes</label> en reembolsos para medicamentos
                  veterinarios con receta.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                  contratación y <label class="textoAzul">1 vez al mes</label>.</label>
                <br>
                <br>
                <label class="text-center encabezado">Vacuna Antirrábica</label>
                <br>
                <br>
                <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                  la vacuna de tu mascota en cualquier clínica.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                  contratación y <label class="textoAzul">1 vez al año</label>.</label>
                <br>
                <br>

              </div>

              <div v-if="indexInfo == 3">

                <label class="text-center encabezado">Vacunas Obligatorias</label>
                <br>
                <br>
                <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                  la vacuna de tu mascota en cualquier clínica.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                  contratación y <label class="textoAzul">2 veces al año</label>.</label>
                <br>
                <br>

              </div>

              <label class="text-center encabezado">Responsabilidad Civil</label>
              <br>
              <br>
              <label class="texto">Tu mascota se portó mal y mordió o
                hizo algún daño a otra persona, te ayudamos en caso de demanda con <label class="textoAzul">UF {{
                  info[8][indexInfo] }}
                </label> si tienes que pagar por los
                daños.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">0 días</label> de
                contratación y <label class="textoAzul">1 vez al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Telemedicina Veterinaria</label>
              <br>
              <br>
              <label class="texto">¿Tienes dudas de salud de tu mascota?
                Deja que nuestros expertos te ayuden llamando sin costo a la hora que necesites.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">{{ info[9][indexInfo] }} días</label> de
                contratación y hasta <label class="textoAzul">{{ info[6][indexInfo] }} veces al año</label>.</label>
              <br>
              <br>

              <div v-if="indexInfo != 3">

                <label class="text-center encabezado">Asesoría Legal Teléfonica</label>
                <br>
                <br>
                <label class="texto">¿Necesitas ayuda con temas legales
                  por algo de tu mascota? Deja que nuestros expertos te ayuden llamando sin costo a la hora que
                  necesites.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                  contratación y hasta <label class="textoAzul">{{ info[7][indexInfo] }} veces al año</label>.
                </label>

              </div>

              <div v-if="indexInfo == 3">

                <label class="text-center encabezado">Cremación</label>
                <br>
                <br>
                <label class="texto">Si a consecuencia de un accidente o por vejez tu mascota fallece, te apoyamos con
                  <label class="textoAzul">3 UF.</label></label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul"> 10 días</label>
                  de
                  contratación.</label>

              </div>

              <br>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialog = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <div v-if="loadingPlans == false">

      <div v-if="tipoPlan == false">

        <div class="planInidivual" style="text-align: center; min-width: 260px;">

          <br>

          <div style="text-align: left; margin-left: 20px;">

            <label class="planInidivualName">{{ planData[0].name }}</label>
            <br>
            <label class="planIndividualPrecio">{{
            formatPrice(planData[0].priceCLP) }}* <label class="planIndividualSubPrecio">/ UF {{
              (planData[0].values[0].price).toFixed(2) }} mensual</label></label>

            </div>

          

          
          <p style="color: #2D387C; font-size: 14px; margin-left: 8%; margin-right: 8%; text-align: start; margin-top: 2%;" >
            Protección completa para tu mascota en caso de urgencias o de que ocurra un accidente.
          </p>
          <!-- <br> -->

          <!-- <label class="planIndividualDetalle">*Precio basado en la edad del
            contratante.</label> -->


          <!-- <div style="max-width: 230px; text-align: left; margin: auto;">

            <label v-if="verMasLiteInput == false" class="planIndividualProtegete"><b>Protege tu salud y tu tranquilidad
                financiera</b></label>

            <label v-if="verMasLiteInput == true" class="planIndividualProtegete"><b>Protege tu salud y tu tranquilidad
                financiera</b></label>

          </div> -->

         

          <div v-if="verMasLiteInput == true" class="text-center">

            <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
              <v-btn :class="newElegirPlanIndividual" style="background-color: #4F74E3;" @click="next(planData[0])">
                <label style="color: white;">CONTRATAR</label>
              </v-btn>
            </div>
            <br>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">Cubre el {{ planData[0].coverage[0].amount }}% de los gastos en casos de urgencias, con un limite máximo de UF
                {{ planData[0].coverage[4].amount }}. {{ planData[0].coverage[0].events }} al año.
              </p>
            </div>

            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">Incluye UF {{ planData[0].coverage[1].amount }} de cobertura en Responsabilidad Civil. {{ planData[0].coverage[1].events }} al año.</p>
            </div>

            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">Consultas médicas en cualquier centro veterinario con una cobertura de UF {{ planData[0].coverage[2].amount }} por evento. {{ planData[0].coverage[2].events }} al año.
              </p>
            </div>


            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">Reembolso mensual de hasta %10.000 en medicamentos, con un tope del 50% de la boleta.</p>
            </div>

            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">Asistencia legal teléfonica incluída. {{ planData[0].coverage[4].events }} al año.</p>
            </div>

            <br>

            <label class="planIndividualVerDetalle" @click="verDetalle(0)">Más información</label>

            <br>
            <br>

          </div>

        </div>

        <v-expansion-panels>
          <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
            <v-expansion-panel-header class="planIndividualVerMas" style="margin-top: -2%; color: white;"
              @click="VerMasLite()">
              <template v-slot:actions>
                <label style="flex-direction: column;"
                  v-if="verMasLiteInput == false">Ver más</label>
                <v-icon v-if="verMasLiteInput == false" style="color: white; margin-top: -5%;">
                  mdi-menu-down
                </v-icon>
                <label style="flex-direction: column;"
                  v-if="verMasLiteInput == true">Ver menos</label>
                <v-icon v-if="verMasLiteInput == true" style="color: white; margin-top: -5%;">
                  mdi-menu-up
                </v-icon>
              </template>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

        <br>
        <br>

        <div class="planFamiliarRecomendado">

          <label class="planFamiliarConveniente">El Más conveniente</label>

        </div>
        <div class="planFamiliar" style="text-align: center;">

          
          <br>
          <div style="text-align: left; margin-left: 20px;">

           <label class="planFamiliarName">{{ planData[1].name }}</label>
            <br>
            <label class="planFamiliarPrecio">{{
            formatPrice(planData[1].priceCLP) }}*
            <label class="planFamiliarSubPrecio">/ UF {{ (planData[1].values[0].price).toFixed(2) }}
              mensual</label></label>

          </div>

          

              <p style="color: white; font-size: 14px; margin-left: 8%; margin-right: 8%; text-align: start; margin-top: 2%; margin-bottom: -4px;" >
                Protege a tu mascota con un 60% del arancel en urgencias veterinarias.
              </p>

          <!-- <br> -->

          <!-- <label class="planFamiliarDetalle">*Precio basado en la edad del
            contratante.</label> -->

          <!-- <div style="max-width: 230px; text-align: left; margin: auto;">

            <label v-if="verMasBasicoInput == false" class="planFamiliarSubDetalle"><b>¡Asegura
                hasta 7 familiares sin costo adicional!</b></label>

            <label v-if="verMasBasicoInput == true" class="planFamiliarSubDetalle"><b>¡Asegura
                hasta 7 familiares sin costo adicional!</b></label>

          </div> -->
          <br>

          <div v-if="verMasBasicoInput == true" class="text-center">

            <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
              <v-btn :class="newElegirPlanFamiliar" style="background-color: #4F74E3;" @click="next(planData[1])">
                <label style="color: white;">CONTRATAR</label>
              </v-btn>
            </div>

            <p></p>
            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">Cubre {{ planData[1].coverage[0].amount }}% de los gastos en casos de urgencia, con un límite máximo de UF {{
              planData[1].coverage[4].amount }} por evento. {{ planData[1].coverage[0].events }} al año.</p>
            </div>
            
            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">Incluye UF {{ planData[1].coverage[1].amount }} de coberturas en Responsabilidad Civil. {{ planData[1].coverage[1].events }} al año.</p>
            </div>

            <p></p>
            
            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">Consultas médicas en cualquier centro veterinario con una cobertura de UF {{ planData[1].coverage[2].amount }} por evento. {{ planData[1].coverage[2].events }} al año.</p>
            </div>
            
            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">Reembolso mensual de hasta $10.000 en medicamentos, con un tope del 50% de la boleta</p>
            </div>
            
            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">Asistencia legal teléfonica incluída. {{ planData[1].coverage[4].events }} al año.</p>
            </div>
            
            <br>

            <label class="planFamiliarVerDetalle" @click="verDetalle(1)">MÁS INFORMACIÓN</label>

            <br>
            <br>

          </div>
        </div>

        <v-expansion-panels>
          <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
            <v-expansion-panel-header class="planFamiliarVerMas" style="margin-top: -2%; color: white;"
              @click="VerMasBasico()">
              <template v-slot:actions>
                <div>
                  <label style="flex-direction: column;"
                    v-if="verMasBasicoInput == false">Ver más</label>
                  <v-icon v-if="verMasBasicoInput == false" style="color: white; margin-top: -5%;">
                    mdi-menu-down
                  </v-icon>
                  <label style="flex-direction: column;"
                    v-if="verMasBasicoInput == true">Ver menos</label>
                  <v-icon v-if="verMasBasicoInput == true" style="color: white; margin-top: -5%;">
                    mdi-menu-up
                  </v-icon>
                </div>
              </template>

            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

        <br>
        <br>

        <div class="planInidivual" style="text-align: center;">

          <br>

          <div style="text-align: left; margin-left: 20px;">

            <label class="planInidivualName">{{ planData[2].name }}</label>
            <br>
            <label class="planIndividualPrecio">{{
            formatPrice(planData[2].priceCLP) }}* <label class="planIndividualSubPrecio">/ UF {{
              (planData[2].values[0].price).toFixed(2) }} mensual</label></label>

          </div>

          <p style="color: #2D387C; font-size: 14px; margin-left: 8%; margin-right: 8%; text-align: start; margin-top: 2%;" >
            Protección básica para tu mascota en caso de que sufra un accidente.
          </p>
          <!-- <br> -->

          <!-- <label class="planIndividualDetalle">*Precio basado en la edad del
            contratante.</label> -->


          <!-- <div style="max-width: 230px; text-align: left; margin: auto;">

            <label v-if="verMasPlusInput == false" class="planIndividualProtegete"><b>Protege
                tu salud y tu tranquilidad financiera</b></label>

            <label v-if="verMasPlusInput == true" class="planIndividualProtegete"><b>Protege tu salud y tu tranquilidad
                financiera</b></label>

          </div> -->

          

          <div v-if="verMasPlusInput == true" class="text-center">

            <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
              <v-btn :class="newElegirPlanIndividual" style="background-color: #4F74E3;" @click="next(planData[2])">
                <label style="color: white;">CONTRATAR</label>
              </v-btn>
            </div>

            <p></p>
            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">Cubre {{ planData[2].coverage[0].amount }}% de los gastos en casos en urgencia, con un límite máximo de  UF
                {{planData[2].coverage[4].amount }} por evento. {{ planData[2].coverage[0].events }} al año.
              </p>
            </div>

            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">
               Incluye UF {{ planData[2].coverage[1].amount }} de cobertura en en Responsabilidad Civil. {{ planData[2].coverage[1].events }} al año.
              </p>
            </div>

            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">
                Consultas médicas en cualquier centro veterinario con una cobertura de UF {{ planData[2].coverage[2].amount }} por evento. {{ planData[2].coverage[2].events }} al año.
              </p>
            </div>

            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">
                Reembolso mensual de hasta $10.000 en medicamentos, con un tope del 50% de la boleta
              </p>
            </div>

            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planIndividualBeneficios">
                Asistencia legal teléfonica incluída. {{ planData[2].coverage[4].events }} al año.
              </p>
            </div>

            <br>

            <label class="planIndividualVerDetalle" @click="verDetalle(2)">MÁS INFORMACIÓN</label>

            <br>
            <br>

          </div>

        </div>

        <v-expansion-panels>
          <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
            <v-expansion-panel-header class="planIndividualVerMas" style="margin-top: -2%; color: white;"
              @click="VerMasPlus()">
              <template v-slot:actions>
                <label style="flex-direction: column;"
                  v-if="verMasPlusInput == false">Ver más</label>
                <v-icon v-if="verMasPlusInput == false" style="color: white; margin-top: -5%;">
                  mdi-menu-down
                </v-icon>
                <label style="flex-direction: column;%"
                  v-if="verMasPlusInput == true">Ver menos</label>
                <v-icon v-if="verMasPlusInput == true" style="color: white; margin-top: -5%;">
                  mdi-menu-up
                </v-icon>
              </template>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

        <br>
        <br>

      </div>

      <div v-if="tipoPlan == true">

        <div class="planFamiliar" style="text-align: center; min-width: 260px;">

          <div class="planFamiliarRecomendado">

            <!-- <label class="planFamiliarConveniente">El Más conveniente</label> -->

          </div>

          <div style="text-align: left;">

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label style="text-align: left;" class=" text-center planFamiliarName">{{
              planSenior[0].name
            }}</label>

          </div>

          <label class="planFamiliarPrecio">{{
            formatPrice(planSenior[0].priceCLP) }}*
            <label class="planFamiliarSubPrecio">/ UF {{ (planSenior[0].values[0].price).toFixed(2) }}
              mensual</label></label>

          <!-- <br> -->

          <!-- <label class="planFamiliarDetalle">*Precio basado en la edad del
            contratante.</label> -->


          <br>

          <!-- <div style="max-width: 230px; text-align: left; margin: auto;">

            <label v-if="verMasSeniorInput == false" class="planFamiliarSubDetalle"><b>¡Asegura
                hasta 7 familiares sin costo adicional!</b></label>

            <label v-if="verMasSeniorInput == true" class="planFamiliarSubDetalle"><b>¡Asegura
                hasta 7 familiares sin costo adicional!</b></label>

          </div> -->

          

          <br>

          <div v-if="verMasSeniorInput == true" class="text-center">

            <div style="text-align: center; display: flex; flex-direction: column; align-items: center;">
            <v-btn :class="newElegirPlanFamiliar" style="background-color: #4F74E3;" @click="next(planSenior[0])">
              <label style="color: white;">CONTRATAR</label>
            </v-btn>
          </div>

            <p></p>
            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">
                Cubre el 50% de los gastos en casos de Urgencia, con un límite máximo de UF 20 por evento. 3 eventos al año.
              </p>
            </div>

            <p></p>
            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">
                Incluye UF 8 de cobertura en Responsabilidad Civil. 1 evento al año.
              </p>
            </div>
            
            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">
                Consultas Médicas en cualquier centro veterinario con una cobertura de UF 5 por evento. 4 eventos al año.
              </p>
            </div>
           
            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">
                Acceso a Telemedicina para consultas veterinarias. 6 eventos al año.
              </p>
            </div>
           
            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">
                Cobertura de UF 0,5 para la Vacuna Antirrábica. 2 eventos al año.
              </p>
            </div>

            <p></p>

            <div style="display: flex; align-items: start; margin-left: 4%;">
              <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
              <p class="planFamiliarBeneficios">
                Cobertura de UF 3 para servicios de cremación de mascotas. 1 evento.
              </p>
            </div>
    
            <br>

            <label class="planFamiliarVerDetalle" @click="verDetalle(3)">MÁS INFORMACIÓN</label>

            <br>
            <br>

          </div>
        </div>

        <v-expansion-panels>
          <v-expansion-panel rounded require dark v-bind="attrs" v-on="on">
            <v-expansion-panel-header class="planFamiliarVerMas" style="margin-top: -2%; color: white;"
              @click="VerMasSenior()">
              <template v-slot:actions>
                <div>
                  <label style="flex-direction: column;"
                    v-if="verMasSeniorInput == false">Ver más</label>
                  <v-icon v-if="verMasSeniorInput == false" style="color: white; margin-top: -5%;">
                    mdi-menu-down
                  </v-icon>
                  <label style="flex-direction: column;"
                    v-if="verMasSeniorInput == true">Ver menos</label>
                  <v-icon v-if="verMasSeniorInput == true" style="color: white; margin-top: -5%;">
                    mdi-menu-up
                  </v-icon>
                </div>
              </template>

            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>

      </div>

    </div>

  </div>
</template>

<script>
import ShieldService from "@/services/shield.service.js";

import check from '@/assets/images/check.svg';

import CryptoJS from 'crypto-js'

export default {
  name: "Plan",
  props: ["planData", "index", "userData", "loadingPlans", "tipoPlan", "planSenior", "planDataAux", "servicesData"],
  data() {
    return {
      secretKey: process.env.VUE_APP_CRYPT,
      newElegirPlanIndividual: 'elegirPlan',
      newElegirPlanFamiliar: 'elegirPlan',
      stepperColor: '#FFB600',
      verMasLiteInput: false,
      verMasBasicoInput: false,
      verMasPlusInput: false,
      verMasSeniorInput: false,
      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      check,
      formatterUF: new Intl.NumberFormat("es-CL"),
      stepId: null,
      e1: 3,
      info: [['Plan Lite', 'Plan Básico', 'Plan Plus', 'Plan Senior'], ['40%', '60%', '80%', '50%'], ['50', '100', '250', '20'], ['2', '2', '3', '3'], ['1', '2', '3', '3'], ['1', '1', '2', '4'], ['3', '4', '6', '6'], ['2', '2', '4', '4'], ['3', '10', '15', '8'], ['15', '15', '15', '30']],
      indexInfo: '0',
      dialog: false,
      document: null,
      couponRules: [
        (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
      ],
      activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    }
  },
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.stepId = sessionStorage.getItem('stepId');
    this.planData.sort((a, b) => b.priceCLP - a.priceCLP);

    console.log("asdasd ", this.planData);
  },
  methods: {
    ufFormat(price) {
      return this.formatterUF.format(price);
    },
    VerMasLite() {
      if (this.verMasLiteInput == false) {
        this.verMasLiteInput = true
        this.newElegirPlanIndividual = 'elegirPlan2'
      } else {
        this.verMasLiteInput = false
        this.newElegirPlanIndividual = 'elegirPlan'
      }
    },
    VerMasBasico() {
      if (this.verMasBasicoInput == false) {
        this.verMasBasicoInput = true
        this.newElegirPlanIndividual = 'elegirPlan2'
      } else {
        this.verMasBasicoInput = false
        this.newElegirPlanIndividual = 'elegirPlan'
      }
    },
    VerMasPlus() {
      if (this.verMasPlusInput == false) {
        this.verMasPlusInput = true
        this.newElegirPlanFamiliar = 'elegirPlan2'
      } else {
        this.verMasPlusInput = false
        this.newElegirPlanFamiliar = 'elegirPlan'
      }
    },
    VerMasSenior() {
      if (this.verMasSeniorInput == false) {
        this.verMasSeniorInput = true
        this.newElegirPlanFamiliar = 'elegirPlan2'
      } else {
        this.verMasSeniorInput = false
        this.newElegirPlanFamiliar = 'elegirPlan'
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    verDetalle(index) {
      this.indexInfo = index;
      this.dialog = true;
    },
    async next(plan) {

      this.planDataAux.plan = plan;

      const planDataLocal = JSON.stringify(this.planDataAux);
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      sessionStorage.setItem('planData', encryptedPlanDataLocal);

      try {

        await ShieldService.newCreateStep(null, null, this.planDataAux, this.servicesData, 'Planes');

      } catch (error) {
        console.log("Cart Fail");
      }

      if (this.userData.email && this.activeCampaingUse == 'TRUE') {
        const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

        this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

        await ShieldService.updateContactActiveCampain(null, this.planDataAux, null, this.servicesData, 1);

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      }

      this.$router.push({ path: '/mt/datos' })
    },
  },
};
</script>

<style scoped>
.labelElige {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.planInidivual {
  width: 100%;
  background: linear-gradient(219deg, #DADCFB  10%, #F5F6FF 50%), #D8D8D8;
  /* background-color: white; */
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;
}

.planInidivualDetalle {
  background: linear-gradient(192.38deg, #C4C6FF 3.57%, #F5F6FF 107.06%), #D8D8D8;
  transform: matrix(1, 0, 0, 1, 0, 0);
  width: 327px;
}

.planInidivualName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 29px;
  color: #343497;
}

.planIndividualPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #343497;
}

.planIndividualSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(53, 53, 53, 0.65);
}

.planIndividualDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(58, 58, 58, 0.85);
}

.planIndividualSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #343497;
}

.planIndividualVerMas {
  background: #4F74E3;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
}

.planIndividualProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.02em;
  color: #343497;
}

.planIndividualCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planIndividualBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #3C3C3C;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  /* text-indent: -15px; */
  text-align: justify;
  margin: auto;
  margin-left: 20px;
}

.planIndividualVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #4040A3;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.elegirPlan {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 39px;
  top: calc(50% - 39px/2 + 100px);
  background: #4F74E3;
  border: 2px solid #4F74E3;
  border-radius: 20px;
}

.elegirPlan2 {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  height: 39px;
  top: calc(50% - 39px/2 + 100px);
  background: #4F74E3;
  border: 2px solid #4F74E3;
  border-radius: 20px;
}

.planFamiliar {
  width: 100%;
  /* background: linear-gradient(to bottom, #2A3881 50%, #F5F6FF 10%); */
  background-color: #2A3881;
  border-radius: 10px 10px 0px 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  margin: auto;
}

.planFamiliarRecomendado {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 25px;
  gap: 8px;
  width: 100%;
  height: 22px;
  border-radius: 27px;
}

.planFamiliarConveniente {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #FFFFFF;
  flex: none;
  order: 0;
  background: linear-gradient(270deg, #4F74E3 -15.95%, #2D387C 40.73%, #4F74E3 115.59%);
  padding: 5px 25px;
  gap: 8px;
  width: 100%;
  height: 22px;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
}

.planFamiliarName {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 29px;
  color: #FFFFFF;
}

.planFamiliarPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.planFamiliarSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.65);
}

.planFamiliarDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.85);
}

.planFamiliarSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: #FFFFFF;
}

.planFamiliarVerMas {
  background: #4F74E3;
  border-radius: 0px 0px 10px 10px;
  width: 100%;
  height: 38.91px;
  text-transform: uppercase;
  margin: auto;
}

.planFamiliarProtegete {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.planFamiliarCaracteristicas {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 128.02%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.planFamiliarBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 191px;
  /* text-indent: -15px; */
  text-align: justify;
  margin: auto;
  margin-left: 20px;
}

.planFamiliarVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2D387C;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #2D387C;
  font-size: 18px;
  font-weight: 600;
}

#app>div>main>div>div>div.v-stepper.v-sheet.theme--light.elevation-0>div.v-stepper__items>div>div>div:nth-child(11)>div:nth-child(2)>div {
  border-radius: 10px 10px 10px 10px;
}

.v-expansion-panel::before {
  content: none;
}
</style>