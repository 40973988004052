<template>
    <!-- <div style="background: #2D387C 0% 0% no-repeat padding-box; opacity: 0.05;"> -->
    <div class=" text-center footer">
      <br>
      <label class="cobertura">El riesgo de este seguro es cubierto por
      </label>
      <br><br>
      <div class="text-center">
        <v-img contain max-height="70px" :src="LogoSB" max-width="170" style=" margin: auto; " />
      </div>
      <!-- <footer class="footer">
        <section style="color:white">
          © {{ year }} Powered By &thinsp;<a style="color:white" href="https://embedx.io/"> EmbedX</a>
        </section>
      </footer> -->
    </div>
  </template>
  
  <script>
  //import LogoSB from "@/assets/images/logo2.jpg"; con background integrado
  import LogoSB from "@/assets/images/newFooter.svg";
  
  export default {
    name: "Footer",
    mounted() {
    },
    data: () => ({
      LogoSB
    }),
  };
  </script>
  
  <style lang="scss" scoped>
  @import url("../styles/main.scss");
  
  .cobertura {
    font-family: Helvetica;
    font-style: normal;
    text-align: center;
    font-size: 15px;
    font-weight: 550;
    color: #232323;
    line-height: 16px;
    opacity: 0.75;
  }
  
  @media only screen and (max-width: 767px) {
    .cobertura {
      font-family: Helvetica;
      font-style: normal;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #232323;
      line-height: 13px;
      opacity: 0.75;
    }
  }
  
  .footer {
    height: 120px;
    //background: rgba(51, 51, 204, 0.062) 0% 0% no-repeat padding-box;
  }</style>