<template>
  <div style="display: flex; align-items: end;">
    <div>

      <v-row justify="center">
        <v-dialog v-model="dialog" width="430px">
          <v-card>
            <v-card-text>
              <div class="text-center">
                <br>
                <br>
                <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Estas son las coberturas y
                  protecciones del</label>
                <br>
                <br>
                <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                  color: black; flex: none; order: 1; flex-grow: 0;">Plan <label style="font-family: Helvetica; font-style: normal; font-weight: 600; 
                  font-size: 20px; line-height: 140%; letter-spacing: 0.02em;
                  color: #2D387C; flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">{{ info[0][indexInfo]
                    }}</label> de
                  Mascotas</label>
                <br>
                <br>
                <label class="text-center encabezado">Libre elección y sin deducible</label>
                <br>
                <br>
                <label class="texto">Puedes llevar a tu mascota a la
                  clínica veterinaria <label class="textoAzul">que tú quieras</label>, sin restricciones. Además, todas
                  nuestras coberturas son <label class="textoAzul">sin
                    deducible</label>.</label>
                <br>
                <br>
                <label class="text-center encabezado">Urgencias Médicas</label>
                <br>
                <br>
                <label class="texto">Te devolveremos hasta el <label class="textoAzul">{{ info[1][indexInfo] }}</label>
                  de
                  tus
                  gastos frente a urgencias veterinarias sin deducibles en cualquier clínica. <label
                    class="textoAzul">Tope
                    UF {{ info[2][indexInfo] }}</label>.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                  contratación y hasta <label class="textoAzul">{{ info[3][indexInfo] }} veces al año</label>.</label>
                <br>
                <br>
                <label class="text-center encabezado">Consultas Médicas</label>
                <br>
                <br>
                <label class="texto">Reembolsamos las consultas médicas y
                  controles de tu mascota con el Veterinario que tú quieras. <label class="textoAzul">Tope UF
                    {{ info[4][indexInfo] }}</label>.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                  contratación y <label v-if="info[5][indexInfo] == 1" class="textoAzul">{{ info[5][indexInfo] }} vez al
                    año</label> <label v-if="info[5][indexInfo] != 1" class="textoAzul">{{ info[5][indexInfo] }} veces
                    al
                    año</label>.</label>
                <br>
                <br>

                <div v-if="indexInfo != 3">

                  <label class="text-center encabezado">Medicamentos</label>
                  <br>
                  <br>
                  <label class="texto">¿Le recetaron remedios a tu mascota?
                    Te damos hasta <label class="textoAzul">$10.000 al mes</label> en reembolsos para medicamentos
                    veterinarios con receta.</label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                    contratación y <label class="textoAzul">1 vez al mes</label>.</label>
                  <br>
                  <br>
                  <label class="text-center encabezado">Vacuna Antirrábica</label>
                  <br>
                  <br>
                  <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                    la vacuna de tu mascota en cualquier clínica.</label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                    contratación y <label class="textoAzul">1 vez al año</label>.</label>
                  <br>
                  <br>

                </div>

                <div v-if="indexInfo == 3">

                  <label class="text-center encabezado">Vacunas Obligatorias</label>
                  <br>
                  <br>
                  <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                    la vacuna de tu mascota en cualquier clínica.</label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                    contratación y <label class="textoAzul">2 veces al año</label>.</label>
                  <br>
                  <br>

                </div>

                <label class="text-center encabezado">Responsabilidad Civil</label>
                <br>
                <br>
                <label class="texto">Tu mascota se portó mal y mordió o
                  hizo algún daño a otra persona, te ayudamos en caso de demanda con <label class="textoAzul">UF {{
                    info[8][indexInfo] }}
                  </label> si tienes que pagar por los
                  daños.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">0 días</label> de
                  contratación y <label class="textoAzul">1 vez al año</label>.</label>
                <br>
                <br>
                <label class="text-center encabezado">Telemedicina Veterinaria</label>
                <br>
                <br>
                <label class="texto">¿Tienes dudas de salud de tu mascota?
                  Deja que nuestros expertos te ayuden llamando sin costo a la hora que necesites.</label>
                <br>
                <br>
                <label class="texto">Puedes usarlo a los <label class="textoAzul">{{ info[9][indexInfo] }} días</label>
                  de
                  contratación y hasta <label class="textoAzul">{{ info[6][indexInfo] }} veces al año</label>.</label>
                <br>
                <br>

                <div v-if="indexInfo != 3">

                  <label class="text-center encabezado">Asesoría Legal Teléfonica</label>
                  <br>
                  <br>
                  <label class="texto">¿Necesitas ayuda con temas legales
                    por algo de tu mascota? Deja que nuestros expertos te ayuden llamando sin costo a la hora que
                    necesites.</label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                    contratación y hasta <label class="textoAzul">{{ info[7][indexInfo] }} veces al año</label>.
                  </label>

                </div>

                <div v-if="indexInfo == 3">

                  <label class="text-center encabezado">Cremación</label>
                  <br>
                  <br>
                  <label class="texto">Si a consecuencia de un accidente o por vejez tu mascota fallece, te apoyamos con
                    <label class="textoAzul">UF 3.</label></label>
                  <br>
                  <br>
                  <label class="texto">Puedes usarlo a los <label class="textoAzul"> 60 días</label>
                    de
                    contratación.</label>

                </div>

                <br>

              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
                elevation="0" text @click="dialog = false">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

    </div>

    <div v-if="planData.name == 'Plan Senior' && tipoPlan == true">

      <div class="planSenior">
          <br>

          <p class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 35px; line-height: 28px;
          color: #FFFFFF; flex: none; order: 0; flex-grow: 0;">{{ planData.name }}</p>

          <label class="text-center" style="color: white; font-size:32px">{{ formatPrice(planData.priceCLP) }}</label>

          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 15px;
          color: rgba(255, 255, 255, 0.8); flex: none;  flex-grow: 0;">UF {{
            (planData.values[0].price).toFixed(2) }}
            mensual</label>

            <div class="plan-info">
              <v-btn class="elegirPlan" style="background-color: #FFFFFF;" @click="validate()">
                <label style="color: #2D387C;">Contratar</label>
              </v-btn>
            </div>
            <br>
      </div>
        
      <div class="plan-skin" v-if="planData.name == 'Plan Senior' && tipoPlan == true">

        <p class="text-center" style="color: #2A3881;">Protege a tu mascota con un <br> 50% del arancel en urgencias <br> veterinarias.</p>


        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios"> 
            Cubre el 50% de los gastos en casos de urgencia, con un límite máximo de UF 20 por evento. 3 eventos al año.
          </p>
        </div>
        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios">
            Incluye UF 8 de cobertura en responsabilidad civil. 1 evento al año.
          </p>
        </div>
        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios">
            Consultas médicas en cualquier centro veterinario con una cobertura de UF 5 por evento. 4 eventos al año
          </p>
        </div>
        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios"> 
            Acceso a telemedicina para consultas veterinarias. 6 eventos al año.
          </p>
        </div>
        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios"> 
            Cobertura de UF 0,5 para la vacuna antirrábica. 2 eventos al año.
          </p>
        </div>  
        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios"> 
            Cobertura de UF 3 para servicios de cremación de mascotas. 1 evento al año.
          </p>
        </div>  
        

        <div class="plan-info">
          <u class="planBasicoVerDetalle" @click="Info(index)"> Más información
          </u>
        </div>

      </div>


    </div>


    <div v-if="planData.name == 'Plan Básico' && tipoPlan == false" style="margin-right: 20px; margin-left: 20px;">

      <div class="plan-recomen">
        <v-col class="text-center">
          <label class="planFamiliarConveniente">El Más conveniente</label>
        </v-col>
      </div>

      <div class="plan">
          <br>

          <p class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 35px; line-height: 28px;
          color: #FFFFFF; flex: none; order: 0; flex-grow: 0;">{{ planData.name }}</p>

          <label class="text-center" style="color: white; font-size:32px">{{ formatPrice(planData.priceCLP) }}</label>

          <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 15px;
          color: rgba(255, 255, 255, 0.8); flex: none;  flex-grow: 0;">UF {{
            (planData.values[0].price).toFixed(2) }}
            mensual</label>

            <div class="plan-info">
              <v-btn class="elegirPlan" style="background-color: #FFFFFF;" @click="validate()">
                <label style="color: #2D387C;">Contratar</label>
              </v-btn>
            </div>
            <br>
      </div>
        
      <div class="plan-skin" v-if="planData.name == 'Plan Básico' && tipoPlan == false">

        <p class="text-center" style="color: #2A3881;">Protege a tu mascota con un <br> 60% del arancel en urgencias <br> veterinarias.</p>


        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios"> 
            Cubre {{planData.coverage[0].amount }}% de los gastos en Urgencia, con un limite máximo de UF {{planData.coverage[4].amount }} por evento. 2 eventos al año.
          </p>
        </div>
        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios">
            Incluye UF{{ planData.coverage[1].amount }} de cobertura en Responsabilidad Civil. 1 evento al año.
          </p>
        </div>
        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios">
            Consultas médicas en cualquier centro veterinario con una cobertura de 
            UF {{planData.coverage[2].amount }} por evento. 1 evento al año.
          </p>
        </div>
        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios"> 
            Reembolso mensual de hasta  $10.000 en medicamentos, con un tope del 50% de la boleta.
          </p>
        </div>
        <div style="display: flex; align-items: start;">
          <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
          <p class="planBasicoBeneficios"> 
            Asistencia Legal Teléfonica incluída 2 eventos al año.
          </p>
        </div>  
        

        <div class="plan-info">
          <u class="planBasicoVerDetalle" @click="Info(index)"> Más información
          </u>
        </div>

      </div>

        

    </div>
      <br>
      <br>



    <div v-if="planData.name != 'Plan Básico' && tipoPlan == false">

      <div class="plan2">
        <v-col class="text-center">

        <br>


        <label class="text-center" style="color: #2D387C; font-size:32px">{{ planData.name }}</label>

        <br>

        <label class="text-center" style="color: #2D387C; font-size:32px">{{ formatPrice(planData.priceCLP) }}</label>

        <br>

        <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 15px;
          color: #2D387C; flex: none; order: 1; flex-grow: 0;">UF {{ (planData.values[0].price).toFixed(2) }}
          mensual</label>

          <div class="plan-info">
            <v-btn class="elegirPlan" style="background-color: #4F74E3;" @click="validate()">
              <label style="color: white;">Contratar</label>
            </v-btn>
          </div>

        </v-col>

      </div>
      <div class="plan-skin2" v-if="planData.name != 'Plan Básico' && tipoPlan == false">

      <p class="text-center" style="color: #2A3881;">Protección {{ planData.coverage[0].proteccion }} para tu <br> mascota en  caso de urgencias o de <br> que ocurra un accidente.</p>
        
      <div style="display: flex; align-items: start;">
        <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
        <p class="planBasicoBeneficios"> 
          Cubre el {{planData.coverage[0].amount}}% de los gastos en casos de urgencia, 
          con un limite máximo de UF {{ planData.coverage[4].amount }} por evento. {{ planData.coverage[0].events }} al año
        </p>
      </div>

      <div style="display: flex; align-items: start;">
        <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
        <p class="planBasicoBeneficios">
          Incluye UF {{ planData.coverage[1].amount}} de cobertura en Responsabilidad Civil. {{ planData.coverage[1].events }} al año.
        </p>
      </div>

      <div style="display: flex; align-items: start;">
        <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
        <p class="planBasicoBeneficios">
          Consultas Médicas en cualquier centro veterinario con una cobertura de  UF {{ planData.coverage[2].amount }} por evento. {{ planData.coverage[2].events }} al años.
        </p>
      </div>
      
      <div style="display: flex; align-items: start;">
        <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
        <p class="planBasicoBeneficios"> 
          Reembolso mensual de hasta $10.000 en medicamentos, con un tope del 50% de la boleta.
        </p>
      </div>
      
      <div style="display: flex; align-items: start;">
        <img :src="check" alt="check" style="margin-right: 5px; margin-top: 6px;">
        <p class="planBasicoBeneficios"> 
          Asistencia Legal Teléfonica incluída. {{ planData.coverage[4].events }} al años
        </p>
      </div>
      
          <div class="plan-info">
            <u class="planBasicoVerDetalle" elevation="0" @click="Info(index)">MÁS INFORMACIÓN </u>
          </div>
    </div>
    
    </div>

    

      

      

    
        

      

    

  </div>
</template>

<script>
import LibreEleccion from "../assets/images/libreeleccion.png"
import ShieldService from "../services/shield.service";

import check from '@/assets/images/check.svg';

import CryptoJS from 'crypto-js'

export default {
  name: "Plan",
  props: ["planData", "planSenior", "index", "tipoPlan", "userData", "planDataAux", "servicesData"],
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    check,
    LibreEleccion,
    info: [['Plan Lite', 'Plan Básico', 'Plan Plus', 'Plan Senior'], ['40%', '60%', '80%', '50%'], ['50', '100', '250', '20'], ['2', '2', '3', '3'], ['1', '2', '3', '3'], ['1', '1', '2', '4'], ['3', '4', '6', '6'], ['2', '2', '4', '4'], ['3', '10', '15', '8'], ['15', '15', '15', '30']],
    indexInfo: '0',
    dialog: false,
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
  }),
  methods: {
    Info(index) {
      if (this.planData.name == 'Plan Senior') {
        this.indexInfo = 3
      } else {
        this.indexInfo = index
      }
      this.dialog = true
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    ufchange(uf) {
      let nn = uf.toString();
      let new_uf = nn.replace('.', ',')
      return new_uf;
    },
    async validate() {

      this.planDataAux.plan = this.planData;

      const planDataLocal = JSON.stringify(this.planDataAux);
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      sessionStorage.setItem('planData', encryptedPlanDataLocal);

      try {

        await ShieldService.newCreateStep(null, null, this.planDataAux, this.servicesData, 'Planes');

      } catch (error) {
        console.log("Cart Fail");
      }

      if (this.userData.email && this.activeCampaingUse == 'TRUE') {
        const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

        this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

        await ShieldService.updateContactActiveCampain(null, this.planDataAux, null, this.servicesData, 1);

        const servicesDataLocal = JSON.stringify(this.servicesData);
        const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
        sessionStorage.setItem('servicesData', encryptedServicesDataLocal);

      }

      this.$router.push({ path: '/mt/datos' })
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2D387C;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.encabezado {
  color: #2D387C;
  font-size: 18px;
  font-weight: 600;
}

.container-details {
  width: 100%;
  margin: auto;
  padding-left: 13%;
}

.plan-info {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan {
  background: linear-gradient(294.61deg, #2D387C -41.93%, #4F74E3 40.77%);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  // border-radius: 10px 10px 0px 0px;
  width: 326px;
}

.plan2 {
  background-color: #f1f4fc;
  // background-image: url("~@/assets/images/planNormal.png");
  background-position: center;
  background-size: cover;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  margin-top: 15%;
  // height: 2000px;
  border-radius: 10px 10px 0px 0px;
}

.planSenior {
  background: linear-gradient(294.61deg, #2D387C -41.93%, #4F74E3 40.77%);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  border-radius: 10px 10px 0px 0px;
  width: 326px;
}

.planFamiliarConveniente {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #FFF;
  ;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.plan-recomen {
  background: linear-gradient(270deg, #4F74E3 -15.95%, #2D387C 40.73%, #4F74E3 115.59%);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  border-radius: 10px 10px 0px 0px;
  width: 326px;
  height: 30px;
}

.plan-skin {
  background: #FFFFFF;
  background-position: center;
  background-size: cover;
  // display: flex;
  flex-direction: column;
  justify-content: center;
  // margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0px 0px 10px 10px;
  width: 326px;
  border-color: #ECECEC;
  border: 1px solid #ECECEC;
}

.plan-skin2 {
  background: #FFFFFF;
  background-position: center;
  background-size: cover;
  // display: flex;
  flex-direction: column;
  justify-content: center;
  // margin: 0.5rem;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0px 0px 10px 10px;
  // width: 326px;
  border-color: #ECECEC;
  border: 1px solid #ECECEC;
}

.planBasicoBeneficios {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: #2D3748;
  flex: none;
  order: 1;
  flex-grow: 0;
  width: 250px;
  text-align: justify;
  margin: 0 auto;
  margin-bottom: 10px;
  // text-indent: -22px;
  padding-left: 5px;
}

.planBasicoVerDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: 1px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #4040A3;
  text-shadow: 4px 8px 24px rgba(251, 148, 0, 0.25);
}

.superpuesto {
  content: 'Nuevo';
  margin-top: -4% !important;
  width: 55px !important;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  background: #4F74E3;
  border-radius: 0px 10px 0px 20px;
  margin-left: 269px;
  margin-bottom: -25px;
}

.elegirPlan {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  width: 200px;
  height: 37px;
  // top: calc(50% - 39px/2 + 100px);
  background: #4F74E3;
  // border: 2px solid #4F74E3;
  border-radius: 20px;
  font-weight: 800;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0px;
  text-transform: uppercase;
}
</style>